/* Teacher Card */
.teacherProfileList {
  display: flex;
  align-content: center;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
}
.infoTable .th_row {
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
}
.infoTable .th_row th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.infoTable .th_row th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.infoTable .th_row th {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  border-bottom: 0;
}


.infoTable tbody tr td {
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
}

.teacherCardBx {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  border-radius: 0px;
  box-shadow: none !important;
}

.teacherCardContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0px 21px;
  max-width: 160px;
  width: auto;
  height: auto;
}

.stuProfile {
  width: 155px !important;
  height: 155px !important;
  margin-bottom: 25px;
}

.stuName {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  font-family: poppins;
  text-transform: capitalize;
}
.Switch{
  margin-top: 10px;
}

.stuName span span {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.8px;
  display: block;
  margin-top: 5px;
}

.stuLastActive {
  font-size: 13px;
  font-weight: 400;
  font-family: poppins;
}

.stuLastActive b {
  font-weight: 700;
}

.stuViewDetailBtn {
  display: flex;
  position: relative;
  justify-content: center;
}

.stuViewDetailCheck {
  position: absolute;
  width: 100%;
  z-index: 99;
  opacity: 0;
}

.stuViewBtn {
  background-color: #000 !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 700 !important;
}

.gridItem {
  border-top: 1px solid #edeced;
  border-left: 1px solid #edeced;
  padding: 25px 40px;
}

.gridItem:first-child {
  border-top: none;
}

.gridItem h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 15px;
}

.gridItem span {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.8px;
  margin-right: 40px;
  display: inline-block;
  margin-bottom: 5px;
}
.gridItem span:last-child {
  margin-right: 0;
}

.gridItem span span {
  font-weight: 700;
  margin-left: 10px;
  text-transform: capitalize;
}
.gridItem span span.exp {
  background-color: #d9d9d9;
  padding: 3px 10px;
  font-weight: 400;
  color: #001c28;
  margin-right: 0;
}

.filter_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.filter_btn {
  margin-right: 20px;
}
.filter_btna {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 70px 8px 10px;
  text-decoration: none;
  color: #000;
  position: relative;
}
.filter_btna:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgB7ZM9DoJAEIXfCiZWhoLCgoKSW4BH8AZ6A28gnsR4EvAGlHRSWFhYoLVmnV0g4W+RII2JX7GzbN68zDI7DJ+wHI/WIP9a4hKHXfIJRuZv+BOGC8empxLIqKJDUzW0bQNT+eY8GdtMxVlZY1CO0jBJUnAc5Z4jSxSxoH4mtCnllGBow3J8Wne5hBK40aLa09T49UMNbTxuIeYmk9cCZn3N1IZN015mApYPP/BEgmucNBSV6yvMRKP07L9qVMWZ4prac5dVqSs9KSszzC2tB+Gjow8dV6wz+qSUK3Tz/zUEt9gwOULNTg6Ds2hC3d3QLsL3hHjx1Rvxok9bQJV+ugAAAABJRU5ErkJggg==);
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
}
.teaViewBtn {
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  background-color: #C33F28;
  font-weight: 700;
}
.stuProfile {
  width: 155px;
  height: 155px;
  margin-bottom: 25px;
}
.rempadd {
  padding-left: 0 !important;
}


.filter_search{
  flex-wrap: wrap;
}


@media(max-width:1650px){
  .filter_search{ margin-bottom: 35px; }
  .filter_search form { margin-bottom: 20px; }
  
}
@media(max-width:1600px){
  .gridItem span{margin-right: 25px;margin-bottom: 5px;}
}
@media(max-width:1450px){
  .rempadd{
    padding-left: 0!important;
  }
  .teacherCardContent img{
    height: 100px!important;
    width: 100px!important;
  }
  .stuName span{font-size: 16px;}
  body .teaViewBtn{
    font-size: 12px;
    padding: 7px 14px;
  }
}
@media(max-width:1280px){
  .teacherCardContent img{
    height: 70px!important;
    width: 70px!important;
  }
}